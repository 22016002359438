.ui-select-container.ui-select-bootstrap.ng-invalid .ui-select-toggle, 
input.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid
{
  border: 1px solid red;
}

pre {
	white-space: pre-wrap;
	word-break: normal;
}

.datepicker-small .btn-sm {
	padding: 2px 5px;
}

.ocular {
  width: 300px;
}
.ocular .line {
  width: 150px !important;
  border: 1px solid red;
  transform-origin: 0 50% 0;
}

 
.table.table-compact {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 4px;
      }
    }
  }
}